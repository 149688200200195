<template>
  <keep-alive>
    <div class="container">
      <div class="s1-gallery-main">
        <el-row>
          <el-col
            class="grow"
            style="margin: 1% 0"
            v-for="projectData in projectsData"
            :key="projectData.projectID"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
          >
            <router-link
              class="el-link el-link--default galleries-link"
              :to="`/tokens/${projectData.projectSlug}`"
            >
              <el-card class="gallery-card">
                <el-image
                  :src="projectData.image"
                  class="image"
                  fit="contain"
                  style="width: 100%; height: auto; aspect-ratio: 1"
                >
                  <template #error>
                    <div
                      style="
                        width: 100%;
                        height: 0;
                        padding-top: 40%;
                        padding-bottom: 60%;
                        font-size: 20px;
                      "
                    >
                      <el-icon :size="50" style="width: 3em; height: 3em">
                        <full-screen style="width: 5em; height: 5em" />
                      </el-icon>
                      <br />Loading...
                    </div>
                  </template>
                </el-image>
                <div style="padding: 14px">
                  <span
                    ><strong>{{
                      galleryName(projectData.projectID)
                    }}</strong></span
                  >
                </div>
                <div class="bottom" style="padding-top: 14px">
                  <span>
                    {{ formatDescription(projectData.description) }}
                  </span>
                </div>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
        <div class="loading" v-loading="loading"></div>
      </div>
    </div>
  </keep-alive>
</template>
<script>
  import { onBeforeMount, computed, ref, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'Galleries',
    components: {},
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { root },
      } = store
      const loading = ref(false)
      const projectsData = computed(() => root.projects)

      const galleryName = (projectID) => {
        const project = projectsData.value.find((p) => p.projectID == projectID)
        return `${project.name} by ${project.author}`
      }

      const formatDescription = (description) => {
        const length = description.length
        let descritpionSuffix = length > 249 ? '...' : ''
        return `${description.substring(0, 250)}${descritpionSuffix}`
      }

      onBeforeMount(async () => {
        projectsData.value.sort((a, b) => (a.projectID < b.projectID ? -1 : 1))
        dispatch('resetTokenAttrs')
        dispatch('resetTokensData')
      })
      return {
        loading,
        projectsData,
        galleryName,
        formatDescription,
      }
    },
  })
</script>
<style scoped>
  .container {
    display: flex;
  }
  .container .el-main {
    float: right;
  }
  .s1-gallery-main {
    overflow: initial !important;
    width: 100%;
  }

  .loading {
    height: 40px;
    margin: 40px;
  }

  .gallery-card {
    margin: 5%;
    min-height: 100%;
  }

  .galleries-link {
    display: inline;
    margin: 1% 0;
  }
  .grow {
    transition: all 0.2s ease-in-out !important;
  }
  .grow:hover {
    transform: scale(1.03) !important;
  }
</style>
